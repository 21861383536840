import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App language="en" />} />
        <Route path="/ja/" element={<App language="ja" />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;