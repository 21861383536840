import React, { useState, useRef } from 'react';
import { DecodeErc } from './ErcCalculation';
import JsonLdComponent from './JsonLdComponent';
import InlineStyles from './InlineStyles';
import ModelList from "./SupportComp";
import translations from './translations';

function App({ language = 'en' }) {
    const [inputCode, setInputCode] = useState('');
    const [decodedResult, setDecodedResult] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Select translations based on language
    const t = translations[language] || translations['en'];

    const handleSubmit = (e) => {
        e.preventDefault();

        if (inputCode.length !== 16) {
            setErrorMessage(t.errorMessage);
            setDecodedResult('');
        } else {
            setErrorMessage('');
            const result = DecodeErc(inputCode);
            setDecodedResult(result);
        }
    };

    const handleGenerateAnother = () => {
        setInputCode('');
        setDecodedResult('');
        setErrorMessage('');
    };

    const whatIsRef = useRef(null);
    const howToRef = useRef(null);
    const executeWhatIsScroll = () => whatIsRef.current.scrollIntoView();
    const executeHowToScroll = () => howToRef.current.scrollIntoView();

    return (
        <div className="app-container">
            <JsonLdComponent /> 
            <InlineStyles /> 
            <div className="erc-decoder">
                <h1>{t.title}</h1>

                <div className="explanation">
                    <h4>{t.explanation}</h4>
                </div>

                <div className="navigationButtons">
                  <button onClick={executeWhatIsScroll}>{t.navigationButtons.whatIsErc}</button>
                  <button onClick={executeHowToScroll}>{t.navigationButtons.howToFind}</button>
                </div>
                
                <div className="erc-form">
                  <form onSubmit={handleSubmit}>
                      <label>
                          {t.inputLabel}
                          <input
                              type="text"
                              placeholder={t.inputPlaceholder}
                              value={inputCode}
                              onChange={(e) => setInputCode(e.target.value)}
                              maxLength={16}
                              minLength={16}
                              required
                          />
                      </label>
                      <button id="ercDecodeButton" type="submit">{t.decodeButton}</button>
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                {decodedResult && (
                    <div className="decoded-result">
                        <p>Decoded ERC: {decodedResult}</p>
                        <button className="generate-button" onClick={handleGenerateAnother}>
                            {t.generateAnotherButton}
                        </button>
                    </div>
                )}
                  </form>
                </div>

                <div className='images'>
                  <div>
                    <img alt='locked out headunit' src="/images/SecurityQuestion.webp" />
                  </div>
                  <div>
                    <img alt='erc unlock screen' src="/images/ERCserial.webp" />
                  </div>
                </div>

                <div ref={whatIsRef}>
                  <h2>{t.whatIsErc.title}</h2>
                  <p>{t.whatIsErc.description}</p>
                </div>

                <div ref={howToRef}>
                  <h2>{t.howToFind.title}</h2>
                  <p>{t.howToFind.description}</p>
                  {t.howToFind.steps.map((step, index) => (
                    <p key={index}>
                      {index + 1}. {step}
                    </p>
                  ))}
                </div>
                
                <div style={{marginTop: '50px'}}>
                  <h1>{t.supportedModels.title}</h1>
                  <p>{t.supportedModels.description}</p>
                  <ModelList />
                </div>
                
                <div className="fb-like" data-href="https://www.facebook.com/people/ErcUnlockcom/61561749016493/" data-width="100" data-layout="" data-action="" data-size="" data-share="true"></div>
            </div>
        </div>
    );
}

export default App;