const translations = {
    en: {
      title: "Free Toyota ERC Headunit Unlock Tool",
      explanation: "Our tool provides a quick and easy way to generate ERC unlock codes for free, helping you restore full functionality to your Japanese Toyota head unit without the need for costly dealership visits.",
      navigationButtons: {
        whatIsErc: "What is ERC?",
        howToFind: "How do I find my 16 digit code?"
      },
      inputLabel: "ERC Code (16 characters):",
      inputPlaceholder: "e.g. 9F8E7D6C5B4A3210",
      decodeButton: "Decode",
      generateAnotherButton: "Generate Another Code",
      errorMessage: "Invalid number of characters.",
      whatIsErc: {
        title: "What is ERC?",
        description: "The Emergency Rescue Code (ERC) is a unique, vehicle-specific code used to unlock the head unit of certain Japanese Toyota vehicles. This code is necessary when the head unit becomes locked, which can occur due to security features activated after events like a battery disconnection or incorrect entry attempts. Without the correct ERC, the head unit remains inoperable, preventing access to important functions such as navigation, audio, and other in-car systems. ERCs ensure that only authorized users can regain control of the head unit, thus enhancing the security of the vehicle's infotainment system."
      },
      howToFind: {
        title: "How do I find my 16 digit code?",
        description: "Finding your 16-digit ERC (Emergency Rescue Code) for your Toyota head unit can vary depending on your vehicle model. Here's a general guide to help you retrieve your ERC code:",
        steps: [
          "Press and Hold the Main Button: On your navigation player, press and hold the main button.",
          "Turn Parking Lights On/Off: Switch your parking lights on and off 3 to 4 times until a new screen appears on the head unit.",
          "Press the Active Buttons: Follow the prompts on the screen and press only the active buttons. This will lead you to a screen displaying the 16-digit ERC serial number.",
          "If these steps do not work for your specific model, you may need to research the procedure for your particular vehicle or consult additional resources."
        ]
      },
      supportedModels: {
        title: "Supported Models",
        description: "Our ERC unlock tool supports a wide range of Toyota head units, including the following models:"
      }
    },
    ja: {
      title: "無料 Toyota ERC ヘッドユニット ロック解除ツール",
      explanation: "私たちのツールは、高価なディーラー訪問なしに、日本のToyotaヘッドユニットのロックを無料で解除する簡単な方法を提供します。",
      navigationButtons: {
        whatIsErc: "ERCとは?",
        howToFind: "16桁のコードをどうやって見つけるの?"
      },
      inputLabel: "ERCコード (16文字):",
      inputPlaceholder: "例: 9F8E7D6C5B4A3210",
      decodeButton: "デコード",
      generateAnotherButton: "別のコードを生成",
      errorMessage: "文字数が無効です。",
      whatIsErc: {
        title: "ERCとは何ですか？",
        description: "緊急救助コード（ERC）は、特定の日本のToyota車両のヘッドユニットをロック解除するための、車両固有の固有のコードです。このコードは、バッテリー切断や不正なエントリー試行などのイベント後に有効化されるセキュリティ機能により、ヘッドユニットがロックされた場合に必要となります。正しいERCがないと、ヘッドユニットは操作不能となり、ナビゲーション、オーディオ、その他の車内システムへのアクセスが防止されます。ERCは、許可されたユーザーのみがヘッドユニットの制御を取り戻せるようにし、車両のインフォテインメントシステムのセキュリティを強化します。"
      },
      howToFind: {
        title: "16桁のコードを見つける方法は？",
        description: "Toyotaヘッドユニットの16桁のERC（緊急救助コード）を取得するには、次の手順で車のヘッドユニットからERCシリアル番号を取得する必要があります：",
        steps: [
          "メインボタンを長押しする：ナビゲーションプレーヤーで、メインボタンを長押しします。",
          "駐車灯のオン/オフ：駐車灯を3〜4回オンとオフに切り替えて、ヘッドユニットに新しい画面を表示させます。",
          "アクティブボタンを押す：画面のプロンプトに従い、アクティブなボタンのみを押します。これにより、16桁のERCシリアル番号が表示される画面が表示されます。",
          "これらの手順が特定のモデルで機能しない場合は、お使いの特定の車両の手順を調べるか、追加のリソースを参照する必要があります。"
        ]
      },
      supportedModels: {
        title: "サポートされているモデル",
        description: "私たちのERCロック解除ツールは、以下のモデルを含む幅広いToyotaヘッドユニットをサポートしています："
      }
    }
  };
  
  export default translations;